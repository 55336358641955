import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
import FeaturedImage from "../../components/FeaturedImage/FeaturedImage"
// import AudioPlayer from "react-h5-audio-player";
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import SEO from '../../components/seo';

const PodcastPost = ({pageContext}) => {
    //let deskImg = <Img fluid={pageContext.acf.desktop_featured_image.localFile.childImageSharp.fluid} />;
    
    return(

        <Wrapper pageCurrent={ `podcast ${pageContext.title.toLowerCase()}` }>
            <SEO title={pageContext.title} />
            <div className="blockwrap menu-spacer">
                <FeaturedImage pageContext={pageContext} 
                    mobile={pageContext.featured_media.mobile_ft.childImageSharp.fluid}
                />  
            </div>

            <div className="blockwrap">
                
                <div className="container base-styles">
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline" dangerouslySetInnerHTML={{ __html: pageContext.title}} />
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.content}} />
                        <div className="audio-player-wrap">
                            <AudioPlayer
            
                                src={pageContext.acf.podcast_url}
                                // other props here
                            />
                            <a className="btn-basic" href="https://podcasts.apple.com/ca/podcast/live-brighter-life-podcast-indranis-light-foundation/id896217633 ">iTunes <i className="fa fa-long-arrow-right"></i></a>
                            <a className="btn-basic" href="https://play.google.com/music/listen#/ps/Itr7r3cdxip7h4cbyrignbddgwi">Android <i className="fa fa-long-arrow-right"></i></a>
                        </div>
                    </div> 
                </div>
            
            </div>
        
        </Wrapper>

    )

}
export default PodcastPost;